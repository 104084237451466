import MuiAlert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import React, { useState , useEffect,useCallback} from "react";
import axios from "axios";
import texts from '../../constants/components/emailAddText';
import agregarFirmante from "../../img/agregarFirmante.svg";

export default function EmailAdd(props) {
  const { handleAddItem, list, error, setError,keycloak } = props;
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [cuit, setCuit] = useState("");
  const [tipoFirma, setTipoFirma] = useState("1");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const isValidCuit = (cuit) => {
    const cleanedCuit = cuit.replace(/\D/g, "");
    const regex = /^[0-9]{11}$/;
    if (!regex.test(cleanedCuit)) {
      return false;
    }

    const cuitArray = cleanedCuit.split("").map(Number);
    const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    const sum = cuitArray
      .slice(0, 10)
      .reduce((acc, digit, index) => acc + digit * weights[index], 0);
    const remainder = (11 - (sum % 11)) % 11;

    return cuitArray[10] === remainder;
  };
  const fetchUserData = useCallback(async (cuitValue) => {
    try {
      const headers = { Authorization: `Bearer ${keycloak}`, "Access-Control-Allow-Origin": "*" };
      const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_INFO_CUIT}?cuit=${cuitValue}`;
      const response = await axios.get(url, { headers, timeout: 20000 });
      const { nombre, apellido, email } = response.data;
  
      if (nombre) setName(nombre);
      if (apellido) setLastName(apellido);
      if (email) setDescription(email);
    } catch (error) {
      setError("No se pudo completar los datos automáticamente.");
      setOpenSnackbar(true);
    }
  }, [keycloak, setOpenSnackbar, setError]);
  
  useEffect(() => {
    if (cuit.length === 11 && isValidCuit(cuit)) {
      fetchUserData(cuit);
    }
  }, [cuit, fetchUserData]);
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name.trim() === "" ||
      lastName.trim() === "" ||
      description.trim() === "" ||
      cuit.trim() === "" ||
      tipoFirma.trim() === ""
    ) {
      setError(texts.errorMessages.completarCampo);
      setOpenSnackbar(true);
      return;
    }

    if (cuit.includes("-")) {
      setError(texts.errorMessages.completarCuitNoGuiones);
      setOpenSnackbar(true);
      return;
    }

    if (!isValidCuit(cuit)) {
      setError(texts.errorMessages.completarCuitValido);
      setOpenSnackbar(true);
      return;
    }

    if (!isValidEmail(description)) {
      setError(texts.errorMessages.completarEmailValido);
      setOpenSnackbar(true);
      return;
    }

    // Verificar duplicados antes de agregar a la lista
    for (const item of list) {
      if (item.cuit === cuit) {
        setError(texts.errorMessages.completarCuitDuplicado);
        setOpenSnackbar(true);
        return;
      }

      if (item.description === description) {
        setError(
          texts.errorMessages.completarEmailDuplicado
        );
        setOpenSnackbar(true);
        return;
      }
    }

    handleAddItem({
      name,
      lastName,
      description,
      cuit,
      tipoFirma,
      done: false,
      id: (+new Date()).toString(),
    });
    setName("");
    setLastName("");
    setDescription("");
    setCuit("");
    setTipoFirma("1");
    setError("");
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/; // Solo permite letras, acentos y espacios
    if (regex.test(value)) {
      setName(value);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/; // Solo permite letras, acentos y espacios
    if (regex.test(value)) {
      setLastName(value);
    }
  };
  return (
    <form className="emailAddForm" onSubmit={handleSubmit}>
      <Grid container spacing={1} >
      <Grid item xs={6} sm={6} md={6}>
          <TextField
            autoComplete="off"
            placeholder={texts.input.cuitCuil}
            variant="outlined"
            id="outlined-size-small"
            size="small"
            value={cuit}
            onChange={(e) => setCuit(e.target.value)}
            InputProps={{ sx: { backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: "30px", color: "black", borderRadius: 0, "&:hover": { backgroundColor: "white", color: "#316094" } } }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              variant="outlined"
              id="outlined-size-small"
              labelId="signature-type-label"
              size="small"
              value={tipoFirma}
              onChange={(e) => setTipoFirma(e.target.value)}
              sx={{ backgroundColor: "white", height: "30px", fontSize: "12px", fontFamily: "robotoMedium", textAlign: "left", borderRadius: 0 }}
            >
              <MenuItem value="1">Electrónica</MenuItem>
              <MenuItem value="0">Digital</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            placeholder={texts.input.name}
            autoComplete
            variant="outlined"
            id="outlined-size-small"
            size="small"
            value={name}
            onChange={handleNameChange}
            InputProps={{ sx: { backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: "30px", color: "black", borderRadius: 0, "&:hover": { backgroundColor: "white", color: "#316094" } } }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            autoComplete
            placeholder={texts.input.lastname}
            variant="outlined"
            id="outlined-size-small"
            size="small"
            value={lastName}
            onChange={handleLastNameChange}
            InputProps={{ sx: { backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: "30px", color: "black", borderRadius: 0, "&:hover": { backgroundColor: "white", color: "#316094" } } }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={10}>
          <TextField
            type="email"
            autoComplete="off"
            placeholder={texts.input.email}
            size="small"
            sx={{ width: "100%", }}
            variant="outlined"
            id="outlined-size-small"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            InputProps={{ sx: { width: "100%", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: "30px", color: "black", borderRadius: 0, "&:hover": { backgroundColor: "white", color: "#316094" } } }}
          />
        </Grid>
       
        <Grid item xs={6} sm={6} md={2}>
          <Button variant="contained" type="submit" sx={{
            padding: "2px 0px",
            minWidth: "40px",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none"
            }, bgcolor: "transparent", boxShadow: "none"
          }}>
            <img
              src={agregarFirmante}
              alt="Agregar Firmante"
              className="agregarFirmante"
            />
          </Button>
        </Grid>
      </Grid>
      {error &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <MuiAlert elevation={6} sx={{ fontFamily: "robotoMedium", color: "white !important" }} variant="filled" onClose={handleCloseSnackbar} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      }
    </form>
  );
}
