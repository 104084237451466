import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import TextField from '@mui/material/TextField';
import { useKeycloak } from "@react-keycloak/web";
import axios from 'axios';
import React, { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import texts from '../constants/components/infoSectionText';
import Sign from "./Sign";


const InfoSection = ({ title, description, startDate, endDate, author, firmantes, idDocument, fechaPub, section, estado,validation_level}) => {
  const [showQRComponent, setShowQRComponent] = useState(false);
  const [openSign, setOpenSign] = useState(false);
  const [qrText, setQrText] = useState("");
  const [hasSigned, setHasSigned] = useState(false);
  const [documentStatus, setDocumentStatus] = useState('');
  const { keycloak } = useKeycloak();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
  const [typeSign, setTypeSign] = useState("");
  const [loading, setLoading] = useState(false);

  const ConfirmRejectDialog = ({ open, onClose, onConfirm }) => {
    return (
      <Dialog open={open}  sx={{ borderRadius: "0px !important" }}>
        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "robotoMedium" }}>
          {texts.confirmRejectDialog.aceptar}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "robotoMedium", color: "black" }} variant="body1">
            {texts.confirmRejectDialog.estasSeguro}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button sx={{ fontFamily: "robotoMedium", color: "black" }} disabled={loading} onClick={onClose} color="primary">
            {texts.confirmRejectDialog.rechazar}
          </Button>
          <Button sx={{ fontFamily: "robotoMedium", backgroundColor: "#316094", borderRadius: "4px", width: "125px", color: "white" }} disabled={loading} onClick={onConfirm} color="primary" autoFocus>
            {texts.confirmRejectDialog.aceptar}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const mostrarCuitFirmantes = useCallback(() => {
    let foundMatch = false;
    firmantes.forEach((firmante) => {
      if (firmante.cuit === keycloak.tokenParsed.cuil) {
        setTypeSign(firmante.tipoFirma);
        foundMatch = true;
      }
    });

    if (!foundMatch && section !== "PUBLICADO") {
      setErrorMessage(texts.errorMessages.errorNoCoincideFirmante);
      setErrorSnackbarOpen(true);
    }
  }, [firmantes, keycloak, section]);


  useEffect(() => {
    mostrarCuitFirmantes();
    const fetchData = async () => {
      setLoading(true);
      try {
        if (idDocument) {
          const docStatusResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_DOCUMENT_STATE}?id=${idDocument}`,
            {
              headers: {
                Authorization: `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
              },
              timeout: 20000,
            }
          );
          setDocumentStatus(docStatusResponse.data);

          const signResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_YES_NO_SIGN}?id=${idDocument}`,
            {
              headers: {
                Authorization: `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
              },
              timeout: 20000,
            }
          );
          setHasSigned(signResponse.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          setErrorMessage(texts.errorMessages.rechazo, error.message);
        } else if (error.code === 'ECONNABORTED') {
          setErrorMessage(texts.errorMessages.peticionTardo);
          setErrorSnackbarOpen(true);
        } else if (error.response && error.response.data) {
          // Extrae y maneja errCode y errorMsg
          const errorMsg = error.response.data.errorMsg;
          setErrorMessage(`${errorMsg}`);
          setErrorSnackbarOpen(true);
        } else {
          setErrorMessage(texts.errorMessages.networkError);
          setErrorSnackbarOpen(true);
        }
      } finally {
        setLoading(false);
      }
    };

    if (section !== "PUBLICADO") { fetchData(); }
  }, [idDocument, keycloak, mostrarCuitFirmantes, section]);


  const handleFirmar = () => {
    setLoading(true);
    if (window.innerWidth >= 600 && typeSign === "ELECTRONICA") {
      if (process.env.REACT_APP_ENV === "prod") {
        setQrText(`https://firmaciudadana.pjm.gob.ar/documents-to-sign/?id=${idDocument}`);
      } else { setQrText(`https://dev-firmaciudadana.pjm.gob.ar/documents-to-sign/?id=${idDocument}`); }
      setShowQRComponent(true);
      setLoading(false);
    } else {
      setOpenSign(true);
      setLoading(false);
    }
  };

  const handleNegarFirma = () => {
    setLoading(true);
    setOpenRejectConfirmation(true);
    setLoading(false);
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-4); // Obtener los últimos dos dígitos del año
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleCerrarModal = () => {
    setOpenSign(false);
    setOpenRejectConfirmation(false);
  };
  const handleCerrarQR = async () => {
    // Actualiza el estado del documento sin recargar la página
    try {
      const signResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_YES_NO_SIGN}?id=${idDocument}`,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
          timeout: 20000,
        }
      );
      setHasSigned(signResponse.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        setErrorMessage(texts.errorMessages.rechazo, error.message);
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
        setErrorSnackbarOpen(true);
      } else if (error.response && error.response.data) {
        // Extrae y maneja errCode y errorMsg
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(texts.errorMessages.obtenerEstadoFirma);
        setErrorSnackbarOpen(true);
      }
    } finally {
      setShowQRComponent(false);
    }
  };


  const handleRejectConfirmation = async () => {
    setLoading(true);
    setOpenRejectConfirmation(false);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_REJECT_DOC}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params: { id: idDocument },
          timeout: 20000,
        }
      );

      window.location.href = '/expired-and-rejected-documents/' + idDocument;
    } catch (error) {
      if (axios.isCancel(error)) {
        setErrorMessage(texts.errorMessages.rechazo, error.message);
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
        setErrorSnackbarOpen(true);
      } else if (error.response && error.response.data) {
        // Extrae y maneja errCode y errorMsg
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(texts.errorMessages.rechazarFirma);
        setErrorSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOC_ID}`, {
        params: { id: idDocument },
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Access-Control-Allow-Origin": "*",
        },
        timeout: 20000,
      });
      if (response.data.url) {
        const { url, docName } = response.data;
        const signedDocName = `${docName}-Firmado`;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", signedDocName || "document-Firmado");
        document.body.appendChild(link);
        link.click();
        if (document.body.contains(link)) {
          document.body.removeChild(link);
        }
      } else {
        setErrorMessage(texts.errorMessages.noHayUrl);
        setErrorSnackbarOpen(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setErrorMessage(texts.errorMessages.rechazo, error.message);
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
        setErrorSnackbarOpen(true);
      } else if (error.response && error.response.data) {
        // Extrae y maneja errCode y errorMsg
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(texts.errorMessages.obtenerUrl,);
        setErrorSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };
  const [openDialogEmail, setOpenDialogEmail] = useState(false);
  const [openDialogResendEmail, setOpenDialogResendEmail] = useState(false);
  const [openDialogConfirmResendEmail, setOpenDialogConfirmResendEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [typeSignDigEl, setTypeSignDigEl] = useState("");
  const [newEmailCuit, setNewEmailCuit] = useState("");
  const handleClickOpenDialogEmail = (email, cuit) => {
    setNewEmail(email);
    setNewEmailCuit(cuit)
    setOpenDialogEmail(true);
  };
  const handleClickOpenDialogResendEmail = (email, sign) => {
    setNewEmail(email);
    setTypeSignDigEl(sign)
    setOpenDialogResendEmail(true);
  };
  const handleClickOpenDialogConfirmResendEmail = () => {
    setOpenDialogConfirmResendEmail(true);
  };
  const handleCloseResendEmail = () => {
    setOpenDialogResendEmail(false);
  };
  const handleCloseConfirmResendEmail = () => {
    setOpenDialogConfirmResendEmail(false);
  };
  const handleCloseDialogEmail = () => {
    setOpenDialogEmail(false);
  };
  const handleSave = async () => {
    setLoading(true);
    try {
      // Realiza la solicitud PUT para actualizar el correo electrónico
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ACTUALIZAR_EMAIL}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            id: idDocument,
            cuitFirmante: newEmailCuit,
            nuevoCorreo: newEmail
          },
          timeout: 20000,
        }
      );
      // Cierra el diálogo después de guardar
      handleCloseDialogEmail();
      window.location.href = `/published-documents?id=${idDocument}`;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Manejo de la cancelación de la solicitud
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
      } else if (error.response && error.response.data) {
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
      } else {
        console.error('Error al hacer la petición:', error.message);
        setErrorMessage(texts.errorMessages.cambiarEmail);
      }
      setErrorSnackbarOpen(true);
    } finally {
      setLoading(false); // Asegúrate de desactivar el loading en el bloque finally
    }
  };
  const [successMessage, setSuccessMessage] = useState("");
  const resendEmail = async () => {
    setLoading(true);
    setSuccessMessage("");
    try {
      let tipoFirma;
      if (typeSignDigEl === "ELECTRONICA") {
        tipoFirma = 1;
      } else {
        tipoFirma = 0;
      }

      // Realiza la solicitud POST para reenviar el correo electrónico
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_REENVIAR_EMAIL}`,
        {
          emails: [newEmail],
          tiposFirmas: [tipoFirma],
          docId: idDocument,
        },
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
          timeout: 20000,
        }
      );
      // Cierra el diálogo después de guardar
      setSuccessMessage("✔️ Ya se ha reenviado el email.");
      handleCloseResendEmail();
    } catch (error) {
      if (axios.isCancel(error)) {
        // Manejo de la cancelación de la solicitud
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
      } else if (error.response && error.response.data) {
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
      } else {
        console.error('Error al hacer la petición:', error.message);
        setErrorMessage(texts.errorMessages.cambiarEmail);
      }
      setErrorSnackbarOpen(true);
    } finally {
      setLoading(false); // Asegúrate de desactivar el loading en el bloque finally
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", width: window.innerWidth > 900 ? "35%" : "100%", marginLeft: window.innerWidth > 900 ? "30px" : "0", marginTop: "10px", }}>
      <Typography variant="h5" sx={{
        fontSize: "20px", fontFamily: "robotoMedium", width: "100%", fontWeight: "bold", whiteSpace: 'normal', /* Permite el ajuste de texto en múltiples líneas */
        overflow: 'hidden', /* Oculta cualquier contenido que se desborde */
        wordWrap: 'break-word'
      }} gutterBottom>
        {title}
      </Typography>
      {description && <Typography style={{ fontSize: "14px", marginBottom: 16, wordBreak: "break-word", width: "100%", fontWeight: "bold", fontFamily: "robotoMedium", }}>{description}</Typography>}
      <Typography sx={{ fontFamily: "robotoMedium", fontSize: "14px !important", display: "flex", alignItems: "center" }}><Typography sx={{ fontWeight: "bold", fontSize: "14px !important" }}>{texts.fechaIn}</Typography> <Box sx={{ width: 5 }} /> {formatFecha(startDate)}</Typography>
      <Typography sx={{ fontFamily: "robotoMedium", fontSize: "14px !important", display: "flex", alignItems: "center" }}><Typography sx={{ fontWeight: "bold", fontSize: "14px !important" }}>{texts.fechaFin}</Typography><Box sx={{ width: 5 }} />  {formatFecha(endDate)}</Typography>
      <Typography sx={{ fontFamily: "robotoMedium", fontSize: "14px !important", display: "flex", alignItems: "center" }}><Typography sx={{ fontWeight: "bold", fontSize: "14px !important" }}>{texts.fechaPub}</Typography><Box sx={{ width: 5 }} />  {formatFecha(fechaPub)}</Typography>
      <Typography sx={{ fontFamily: "robotoMedium", fontSize: "14px", display: "flex", alignItems: "center" }}><Typography sx={{ fontWeight: "bold", fontSize: "14px !important" }}> {texts.publicado}</Typography> <Box sx={{ width: 5 }} /> {author}</Typography>
      <div style={{ marginBottom: 16, overflowX: "auto" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "robotoMedium", fontSize: "16px", paddingBottom: "3px" }}>{texts.firmantesTable.title}</Typography>
        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <Box>
            {firmantes.map((firmante) => (
              <Box
                key={firmante.email}
                sx={{
                  borderBottom: "1px solid #316094",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px 0px 9px 0px"
                }}
              >
                {/* Nombre + Apellido y CUIT en la misma línea */}
                <Box
                  sx={{
                    display: "flex",
                    fontFamily: "robotoMedium",
                    fontSize: "14px",
                    padding: "3px 0px"
                  }}
                >
                  <Box sx={{ display: "flex" }}>{firmante.nombre}<Box sx={{ width: 5 }} />{firmante.apellido}</Box>
                </Box>
                {/* Email debajo */}
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Para separar email del ícono
                  fontFamily: "robotoMedium",
                  fontSize: "14px",
                  padding: "3px 0px"
                }}>
                  <Box sx={{ flexGrow: 1 }}>{firmante.email}</Box>
                  {section === "PUBLICADO" && firmante.estadoFirma !== "FIRMADO" && (estado !== "CERRADO" && estado !== "VENCIDO" && estado !== "RECHAZADO") && (
                    <>
                      <IconButton onClick={() => handleClickOpenDialogEmail(firmante.email, firmante.cuit)} sx={{ padding: "0px 8px !important" }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      {estado !== "PENDIENTE" && firmante.estadoFirma === "PENDIENTE" && (
                        <IconButton onClick={() => handleClickOpenDialogResendEmail(firmante.email, firmante.tipoFirma)} sx={{ padding: "0 !important" }}>
                          <EmailIcon fontSize="small" />
                        </IconButton>
                      )}
                      <Dialog open={openDialogEmail} BackdropProps={{
                        sx: {
                          backgroundColor: errorMessage ? "transparent" : "rgba(0, 0, 0, 0.5)",
                        },
                      }} PaperProps={{ sx: { width: '60%', maxWidth: 'none' } }} >
                        <IconButton
                          edge="end"
                          onClick={handleCloseDialogEmail}
                          sx={{
                            display: "flex", justifyContent: "flex-end", width: "100%", boxShadow: "none", // Desactiva la sombra
                            "&:hover": {
                              backgroundColor: "transparent", // Evita que cambie de color al pasar el mouse
                            },
                            "&:focus": {
                              outline: "none", // Quita el contorno del enfoque
                            },
                          }}
                        >
                          <CloseIcon sx={{ color: "gray" }} />
                        </IconButton>
                        <DialogTitle sx={{ fontFamily: "robotoMedium" }}>{texts.firmantesTable.editarEmail}</DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} onClick={handleCloseDialogEmail}>{texts.buttons.cancel}</Button>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} disabled={loading} onClick={handleSave}>
                            {loading ? <CircularProgress size={24} sx={{ color: "#316094" }} /> : texts.buttons.save}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={openDialogResendEmail}
                        BackdropProps={{
                          sx: {
                            backgroundColor: errorMessage ? "transparent" : "rgba(0, 0, 0, 0.5)",
                          },
                        }}
                        sx={{ maxWidth: 'none', width: "80%", margin: "auto" }}
                        PaperProps={{ sx: { maxWidth: 'none', width: "100%" } }}
                        
                      >
                        <IconButton
                          edge="end"
                          onClick={handleCloseResendEmail}
                          sx={{
                            display: "flex", justifyContent: "flex-end", width: "100%", boxShadow: "none", // Desactiva la sombra
                            "&:hover": {
                              backgroundColor: "transparent", // Evita que cambie de color al pasar el mouse
                            },
                            "&:focus": {
                              outline: "none", // Quita el contorno del enfoque
                            },
                          }}
                        >
                          <CloseIcon sx={{ color: "gray" }} />
                        </IconButton>
                        <DialogTitle sx={{ fontFamily: "robotoMedium", display: 'flex', alignItems: 'center' }}>
                          <EmailIcon sx={{ marginRight: 1 }} />
                          Se enviará un nuevo email al firmante recordándole firmar este documento

                        </DialogTitle>
                        <DialogContent sx={{ marginTop: 2 }}>
                          <Typography variant="body2" sx={{ fontFamily: "robotoMedium", color: "#555" }}>
                            Se le notificará al siguiente email: <strong style={{ color: "#316094" }}>{newEmail}</strong>
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} onClick={handleCloseResendEmail}>
                            {texts.buttons.cancel}
                          </Button>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} disabled={loading} onClick={handleClickOpenDialogConfirmResendEmail}>
                            {loading ? <CircularProgress size={24} sx={{ color: "#316094" }} /> : "Enviar"}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={openDialogConfirmResendEmail}
                        BackdropProps={{
                          sx: {
                            backgroundColor: errorMessage ? "transparent" : "rgba(0, 0, 0, 0.5)",
                          },
                        }}
                        sx={{ width: "100%" }}
                        PaperProps={{ width: "100%" }}
                      >
                        <IconButton
                          edge="end"
                          onClick={handleCloseConfirmResendEmail}
                          sx={{
                            display: "flex", justifyContent: "flex-end", width: "100%", boxShadow: "none", // Desactiva la sombra
                            "&:hover": {
                              backgroundColor: "transparent", // Evita que cambie de color al pasar el mouse
                            },
                            "&:focus": {
                              outline: "none", // Quita el contorno del enfoque
                            },
                          }}
                        >
                          <CloseIcon sx={{ color: "gray" }} />
                        </IconButton>
                        <DialogContent >
                          <Typography variant="body2" sx={{ fontFamily: "robotoMedium", fontSize: "18px" }}>
                            {successMessage ? successMessage : "¿Confirma la operación?"}
                          </Typography>
                        </DialogContent>
                        {successMessage ? null : <DialogActions>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} onClick={handleCloseConfirmResendEmail}>
                            {texts.buttons.cancel}
                          </Button>
                          <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} disabled={loading} onClick={resendEmail}>
                            {loading ? <CircularProgress size={24} sx={{ color: "#316094" }} /> : "Confirmar"}
                          </Button>
                        </DialogActions>}
                      </Dialog>
                    </>
                  )}
                </Box>
                {/* cuit */}
                <Box sx={{ fontFamily: "robotoMedium", fontSize: "14px", padding: "5px 0px", display: "flex" }}>
                  CUIL<Box sx={{ width: 5 }} />{firmante.cuit}
                </Box>
                {/* Tipo de firma */}
                <Box sx={{ fontFamily: "robotoMedium", fontSize: "14px", padding: "3px 0px", display: "flex" }}>
                  Firma<Box sx={{ width: 5 }} />{firmante.tipoFirma}
                </Box>
                {/* Firma y Estado del documento */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Ajuste en pantallas pequeñas
                    fontFamily: "robotoMedium",
                    fontSize: "14px",
                  }}
                >
                  <Box sx={{ padding: "3px 0px", fontWeight: "bold", display: "flex" }}>Estado de la firma:<Box sx={{ width: 5 }} />{firmante.estadoFirma}</Box>
                  <Box sx={{ padding: "3px 0px", fontWeight: "bold", display: "flex" }}>
                    último cambio:<Box sx={{ width: 5 }} />{formatFecha(firmante.fechaEstadoFirma)}
                  </Box>
                </Box>


              </Box>
            ))}
          </Box>

        </div>
      </div>
      {!loading ? (
        documentStatus === "PUBLICADO" && hasSigned ? (
          <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
            <Typography style={{ display: "inline-block", marginRight: 8 }}>
              <Button
                onClick={handleNegarFirma}
                disabled={loading}
                style={{
                  color: "#000000",
                  fontFamily: "robotoRegular",
                  textDecoration: "none",
                  background: "none", 
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                {texts.buttons.denegar}
              </Button>
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#316094",
                fontFamily: "robotoMedium",
                borderRadius: "4px",
                width: "110px",
                '&:hover': {
                  bgcolor: "#316094",
                },
              }}
              onClick={handleFirmar}
              disabled={loading}
            >
              {loading ? <CircularProgress sx={{ color: "#316094" }} size={24} /> : texts.buttons.confirmar}
            </Button>
          </div>
        ) : documentStatus === "RECHAZADO" ? (
          <div style={{ fontFamily: "robotoMedium", color: "red" }}>
            {texts.documentStatus.rechazo}
          </div>
        ) : documentStatus === "VENCIDO" ? (
          <div style={{ textAlign: "center", fontFamily: "robotoMedium", color: "red" }}>
            {texts.documentStatus.vencido}
          </div>
        ) : documentStatus === "PUBLICADO" && !hasSigned ? (
          <div style={{ fontFamily: "robotoMedium", color: "#FFD300" }}>
            {texts.documentStatus.proceso}
          </div>
        ) : documentStatus === "CERRADO" ? (
          <div>
            <div style={{ fontFamily: "robotoMedium", color: "green", marginBottom: "10px" }}>
              {texts.documentStatus.firmado}
            </div>
            <Button
              variant="contained"
              sx={{ bgcolor: "#316094", fontFamily: "robotoMedium", borderRadius: "4px", width: "110px" }}
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? <CircularProgress sx={{ color: "#316094" }} size={24} /> : texts.buttons.dowload}
            </Button>
          </div>
        ) : section === "PUBLICADO" && estado === "CERRADO" ? (
          <div>
            <Button
              variant="contained"
              sx={{ bgcolor: "#316094", fontFamily: "robotoMedium", borderRadius: "4px", width: "110px" }}
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? <CircularProgress sx={{ color: "#316094" }} size={24} /> : texts.buttons.dowload}
            </Button>
          </div>
        ) : null
      ) : (
        <CircularProgress sx={{ color: "#316094" }} />
      )}
      {typeSign === "ELECTRONICA" ? <Dialog open={openSign}  maxWidth="xs" fullWidth>
        <div style={{ padding: '35px', justifyContent: 'center', textAlign: 'center' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCerrarModal}
            aria-label="close"
            style={{
              position: "absolute",
              top: 1,
              right: 15,
            }}
          >
            <CloseIcon sx={{ color: "#316094" }} />
          </IconButton>
          <Sign idDocument={idDocument} keycloak={keycloak} typeSign={typeSign} />
        </div>
      </Dialog> : null}
      {typeSign === "DIGITAL" ? <Dialog open={openSign}  maxWidth="xs" fullWidth>
        <div style={{}}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCerrarModal}
            aria-label="close"
            style={{
              position: "absolute",
              top: 1,
              right: 15,
            }}
          >
            <CloseIcon sx={{ color: "#316094" }} />
          </IconButton>
          <Sign idDocument={idDocument} keycloak={keycloak} typeSign={typeSign} validation_level={validation_level}/>
        </div>
      </Dialog> : null}

      <Dialog open={showQRComponent}  sx={{ borderRadius: "0px", }} maxWidth="xs" fullWidth>
        <div style={{ position: "relative", padding: "35px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#E2E2E5", overflow: "hidden" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCerrarQR}
            aria-label="close"
            style={{
              position: "absolute",
              top: 1,
              right: 15,
            }}
          >
            <CloseIcon sx={{ color: "#316094" }} />
          </IconButton>
          <Typography sx={{ fontFamily: "robotoMedium", color: "#316094", marginBottom: "10px", textAlign: "center" }}>
            {texts.qr}
          </Typography>
          <QRCode style={{ padding: "10px", border: "2px solid #316094", maxWidth: '100%', height: 'auto', marginBottom: 16 }} value={qrText} />
        </div>
      </Dialog>

      <ConfirmRejectDialog
        open={openRejectConfirmation}
        onClose={() => setOpenRejectConfirmation(false)}
        onConfirm={handleRejectConfirmation}
      />

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setErrorSnackbarOpen(false)}
          severity="error"
          sx={{ fontFamily: "robotoMedium", }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InfoSection;
