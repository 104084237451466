
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import texts from '../constants/views/signerInterfaceText';
import DocumentsInProcess from "./DocumentsInProcess";
import DocumentsToSign from "./DocumentsToSign";
import ExpiredAndRejectedDocuments from "./ExpiredAndRejectedDocuments";
import SignedDocuments from "./SignedDocuments";

const SignerInterface = ({ keycloak, cuil, validation_level }) => {
  return cuil ? (
    <Routes>
      <Route path="/signed-documents/*" element={<SignedDocuments keycloak={keycloak?.token} />} />
      <Route path="/documents-in-process/*" element={<DocumentsInProcess keycloak={keycloak?.token} />} />
      <Route path="/documents-to-sign/*" element={<DocumentsToSign keycloak={keycloak?.token} validation_level={validation_level}/>} />
      <Route path="/expired-and-rejected-documents/*" element={<ExpiredAndRejectedDocuments keycloak={keycloak?.token} />} />
      {/* Agregar ruta default */}
      <Route path="/" element={<Navigate to="/documents-to-sign" />} />
    </Routes>

  ) : (<div style={{ fontFamily: "robotoMedium" }}>{texts.noCuil}</div>);
};

export default SignerInterface;
