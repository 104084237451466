import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";
import texts from '../constants/components/signText.js';
import abrirFirmador from '../functions/firmador.js';
import useCrypto from '../functions/useCrypto';
import "./css/Sign.css";

const Main = ({ idDocument, keycloak, typeSign,validation_level }) => {
  return (
    <div>
      {typeSign === 'ELECTRONICA' ? (
        <SignatureComponent idDocument={idDocument} keycloak={keycloak.token} />
      ) : (
        <SignatureComponentDigital idDocument={idDocument} keycloak={keycloak} validation_level={validation_level}/>
      )}
    </div>
  );
};

export default Main;

const SignatureComponent = ({ idDocument, keycloak }) => {
  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const { encryptedData, encryptData } = useCrypto();
  const [signatureBase64, setSignatureBase64] = useState(null);
  const [popupState, setPopupState] = useState({ showSuccessPopup: false, showErrorPopup: false });
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const agregarSign = process.env.REACT_APP_PUT_SIGN;
  const documentoUrl = baseURL + agregarSign;

  useEffect(() => {
    const canvas = canvasRef.current;
    signaturePadRef.current = new SignaturePad(canvas, { penColor: "blue" });

    return () => {
      signaturePadRef.current.off();
    };
  }, []);

  useEffect(() => {
    if (encryptedData !== null) {
      const dataToSend = {
        docId: idDocument,
        datosBiometricos: encryptedData,
        signBase64: signatureBase64,
        fecha: getCurrentDateFormatted()
      };

      axios.put(documentoUrl, dataToSend, {
        headers: {
          Authorization: `Bearer ${keycloak}`,
          "Access-Control-Allow-Origin": "*",
        },
        timestamp: 20000,
      })
        .then(response => {
          if (response.status === 200) {
            setPopupState({ showSuccessPopup: true, showErrorPopup: false });
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            setErrorMessage(texts.errorMessages.rechazo, error.message);
          } else if (error.code === 'ECONNABORTED') {
            setErrorMessage(texts.errorMessages.peticionTardo);
            setErrorSnackbarOpen(true);
          } else {
            const errorMsg = error.response?.data?.errorMsg || texts.errorMessages.networkError;

            setErrorMessage(errorMsg);
            setErrorSnackbarOpen(true);
            setPopupState({ showSuccessPopup: false, showErrorPopup: true });
          }
          setIsLoading(false);
        })
    }
  }, [encryptedData, signatureBase64, idDocument, documentoUrl, keycloak]);


  const getCurrentDateFormatted = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };

  const closePopup = async () => {
    if (popupState.showSuccessPopup) {
      try {
        const signResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_YES_NO_SIGN}?id=${idDocument}`, {
          headers: {
            Authorization: `Bearer ${keycloak}`,
            "Access-Control-Allow-Origin": "*",
          },
        });

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_DOCUMENT_STATE}?id=${idDocument}`, {
          headers: {
            Authorization: `Bearer ${keycloak}`,
            "Access-Control-Allow-Origin": "*",
          },
        });

        const estadoDelDocumento = response.data;
        if (estadoDelDocumento === 'CERRADO') {
          setPopupState({ showSuccessPopup: false, showErrorPopup: false });
          window.location.href = '/signed-documents/?id=' + idDocument;
        } else if (estadoDelDocumento === 'PUBLICADO' && signResponse.data === false) {
          setPopupState({ showSuccessPopup: false, showErrorPopup: false });
          window.location.href = '/documents-in-process/?id=' + idDocument;
        } else {
          setPopupState({ showSuccessPopup: false, showErrorPopup: false });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          setErrorMessage(texts.errorMessages.rechazo, error.message);
        } else if (error.code === 'ECONNABORTED') {
          setErrorMessage(texts.errorMessages.peticionTardo);
          setErrorSnackbarOpen(true);
          setPopupState({ showSuccessPopup: false, showErrorPopup: true });
        } else {
          const errorMsg = error.response?.data?.errorMsg || texts.errorMessages.obtenerEstadoDoc;

          setErrorMessage(errorMsg);
          setErrorSnackbarOpen(true);
          setPopupState({ showSuccessPopup: false, showErrorPopup: true });

        }
      }
    } else {
      setPopupState({ showSuccessPopup: false, showErrorPopup: false });
    }
  };


  const handleSaveImage = async () => {
    setIsLoading(true);
    const signaturePad = signaturePadRef.current;
    const data = signaturePad.toData();

    if (data && data.length > 0) {
      const captureDateTime = new Date();
      const signatureBase64 = canvasRef.current.toDataURL();
      setSignatureBase64(signatureBase64.split(',')[1]);
      const representationBody = data[0].points.map((point) => ({
        x: point.x,
        y: point.y,
        t: point.time,
      }));

      const signatureJSON = {
        GeneralHeader: {
          FormatIdentifier: "SDI/0",
          VersionNumber: "020/0",
          RecordLength: 64,
          NumberOfRepresentations: 1,
          CertificationFlag: 0,
        },
        RecordBody: {
          RepresentationBodyHeader: {
            RepresentationLength: 64,
            CaptureDateTime: {
              Year: captureDateTime.getUTCFullYear(),
              Month: captureDateTime.getUTCMonth() + 1,
              Day: captureDateTime.getUTCDate(),
              Hour: captureDateTime.getUTCHours(),
              Minute: captureDateTime.getUTCMinutes(),
              Second: captureDateTime.getUTCSeconds(),
              Millisecond: captureDateTime.getUTCMilliseconds(),
            },
            CaptureDeviceID: 0,
            CaptureDeviceVendorID: 0,
            CaptureDeviceTypeID: 0,
            QualityRecord: 0,
            ChannelDescriptions: [193, 0, 0, 0, 0],
            NumberOfSimplePoints: representationBody.length,
            RepresentationBody: representationBody,
            ExtendedData: 0,
          },
        },
      };

      const jsonString = JSON.stringify(signatureJSON);
      const binaryString = stringToBinary(jsonString);
      const publicKey = '-----BEGIN PUBLIC KEY-----' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAinGpyKvygcdktnSMGVay' +
        'sPyDeWt/GnTHOc7nXZbElZFCUBOAU3NPk6EHw6/BQKbRL4kBy8622KVGXXIQZDMr' +
        '4m1MZ2nX7PSVHbivlb8aI0e4O9S5KqLbNpfpOkt7rXaiPGylsJvMLwf4yLm3t1oz' +
        'KD5g+EoS52Vm4tcimASDyDEpU+2gLvBBbihGCpxiQ+m2LfTMyleFiLNuFGqVqkij' +
        'M/N1dlWKwz8DjwR0sE+n+jcE7zEyCw+qxdaOBtW6CqCCoFFsSWovV1AGwLxhjCgF' +
        'OsU+dPfFrx2xKXcvjJbdcwVADkrypCNuFQNLH3rS0f36U2Z/LsfMSZmYhqFdeZ3c' +
        'OQIDAQAB' +
        '-----END PUBLIC KEY-----';
      await encryptData(binaryString, publicKey);
    } else {
      setIsLoading(false);
    }
  };

  const stringToBinary = (string) => {
    const binaryArray = [];
    for (let i = 0; i < string.length; i++) {
      binaryArray.push(string.charCodeAt(i).toString(2));
    }
    return binaryArray.join(" ");
  };

  const handleClearSignature = () => {
    const signaturePad = signaturePadRef.current;
    signaturePad.clear();
  };

  return (
    <div className="signature-container">
      <canvas className="signature-canvas" ref={canvasRef} height={200}></canvas>
      <div className="button-container">
        <button className="action-button" style={{
          color: "#000000",
          fontFamily: "robotoMedium",
          textDecoration: "none",
          background: "none",
          border: "none",
          padding: 0,
          fontWeight: "bold",
          cursor: "pointer",
          font: "inherit"
        }} onClick={handleClearSignature}>
          {texts.buttons.clean}
        </button>
        <button className="action-button" style={{ border: 0, color: "white", backgroundColor: isLoading ? "transparent" : "#316094", fontFamily: "robotoMedium", borderRadius: "4px", width: "110px",cursor: isLoading ? "not-allowed" : "pointer",  // Cambia el cursor cuando esté deshabilitado
    opacity: isLoading ? 0.6 : 1  // Reduce la opacidad si está deshabilitado
     }} onClick={handleSaveImage} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} sx={{ color: "#316094" }} /> : texts.buttons.confirmar}
        </button>
      </div>
      <Snackbar open={popupState.showSuccessPopup} autoHideDuration={6000} onClose={closePopup}>
        <MuiAlert onClose={closePopup} severity="success" sx={{ width: "100%" }}>
          {texts.signStatus.exito}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={popupState.showErrorPopup} autoHideDuration={6000} onClose={closePopup}>
        <MuiAlert onClose={closePopup} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const SignatureComponentDigital = ({ idDocument, keycloak,validation_level }) => {
  const base64Data = {
    firmanteCuit: keycloak.tokenParsed.cuil,
    docId: idDocument,
    validation_level: validation_level,
  };
  const jsonData = JSON.stringify(base64Data);
  const isDesktop = window.innerWidth > 900;
  const [docStatus, setDocStatus] = useState(null);
  const [signResponse, setSignResponse] = useState(null);
  const [errorPopperOpen, setErrorPopperOpen] = useState(false);
  const [firmaCompleta, setFirmaCompleta] = useState(false);

  const handleMessageFromFirmador = useCallback(async (message) => {
    /* console.log("message", message) */
    if (message.includes("Proceso de firma cancelado") || message.includes("El usuario cancela sin iniciar proceso de firma") ||
      message.includes("Proceso de Firma Cancelado") ||
      message.includes("INFO el servidor cerró sesión de websocket. El usuario cancela sin iniciar proceso de firma") ||
      message.includes("INFO el servidor cerró sesión de websocket. Proceso de firma cancelado")) {
      window.location.href = '/documents-to-sign/?id=' + idDocument;
      console.log("El servidor cerró la sesión de WebSocket.");
    }

    if (message.includes("COMPLETADO:Proceso de firma completado.") || message.includes("Se procesaron 1 de  1 documentos a la firma") || message.includes("Recibidos: 1 de 1")) {
      try {
        // Solicitudes para obtener el estado del documento
        const signResponseQuery = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_YES_NO_SIGN}?id=${idDocument}`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
        });

        const docStatusResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_DOCUMENT_STATE}?id=${idDocument}`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setDocStatus(docStatusResponse.data); // Guardar estado del documento
        setSignResponse(signResponseQuery.data);
        if (docStatusResponse.data === 'CERRADO') {
          setFirmaCompleta(true);
          window.location.href = '/signed-documents/?id=' + idDocument;
        } else if (docStatusResponse.data === 'PUBLICADO' && signResponseQuery.data === false) {
          setFirmaCompleta(true);
          window.location.href = '/documents-in-process/?id=' + idDocument;
        } else {
          console.error('Estado del documento desconocido');
          console.error(docStatus);
          console.error(signResponse);
        }
      } catch (error) {
        console.error(texts.errorMessages.obtenerEstadoDoc, error);

        const errorMsg = error.response?.data?.errorMsg || texts.errorMessages.obtenerEstadoDoc;

        console.error(errorMsg);
        setErrorPopperOpen(true);
      }
    }
  }, [idDocument, keycloak.token, docStatus, signResponse]);


  const handleFirmadorError = (error) => {
    console.error(texts.errorMessages.firmador, error);
    setErrorPopperOpen(true);
  };

  useEffect(() => {
    if (!firmaCompleta) {
      const base64EncodedData = btoa(jsonData);
      abrirFirmador(base64EncodedData, `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_PUT_DIGITAL_SIGN}`, "estampafirma_pjm.gif", handleMessageFromFirmador, handleFirmadorError);
    }
  }, [firmaCompleta, jsonData, handleMessageFromFirmador]);

  const closeErrorMessage = () => {
    setErrorPopperOpen(false)
  };
  return (
    <>
      {errorPopperOpen && isDesktop ? (
        <Snackbar open={errorPopperOpen} autoHideDuration={6000} onClose={closeErrorMessage}>
          <MuiAlert onClose={closeErrorMessage} severity="error" sx={{ width: "100%" }}>
            {texts.errorMessages.noFirma}<a href="https://signer-update.pjm.gob.ar/">este enlace</a>.
          </MuiAlert>
        </Snackbar>
      ) : (
        !isDesktop && errorPopperOpen && (
          <Snackbar open={!isDesktop} autoHideDuration={6000} onClose={closeErrorMessage}>
            <MuiAlert onClose={closeErrorMessage} severity="info" sx={{ width: "100%" }}>
              {texts.errorMessages.firmarDigitalmente}
            </MuiAlert>
          </Snackbar>
        )
      )}
    </>
  );
};

